<template>
  <div class="directivesWrap">
    <div class="searchWrap">
      <span class="label"> 指令名称： </span>
      <el-input
        v-model="searchValue"
        placeholder="请输入内容"
        clearable
      ></el-input>
      <span class="label"> 类型： </span>
      <el-select v-model="selectedType" placeholder="请选择" clearable>
        <el-option
          v-for="item in directiveTypes"
          :key="item.id"
          :label="item.type_name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <el-button type="primary" icon="el-icon-search" @click="search"
        >搜索</el-button
      >
<!--      <el-button type="primary" icon="el-icon-upload2" @click="dialogFormVisible=!dialogFormVisible"-->
<!--      >导入模板</el-button-->
<!--      >-->
    </div>
    <div class="operations">
      <el-button type="primary" @click="$router.push('/privateResource/manage/add')"
        >添加</el-button
      >
      <el-button
        type="danger"
        @click="deleteDirective(selectedDirectives.map((item) => item.id))"
        >删除</el-button
      >
    </div>
    <div class="directivesList">
      <el-table
        ref="multipleTable"
        :data="directivesListData.result"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          :prop="c.key"
          :label="c.label"
          show-overflow-tooltip
          v-for="c in columns"
          :key="c.key"
        >
          <template slot-scope="scope">
            <span v-if="c.key == 'create_time'">{{
              getTime(scope.row.create_time)
            }}</span>
            <span v-else-if="!!c.format">{{ c.format(scope.row[c.key]) }}</span>
            <span v-else>{{ scope.row[c.key] }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="operation"
          label="操作"
          width="150px"
          key="operation"
        >
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="toEdit(scope.row.id)"
              >编辑</el-button
            >
            <el-button
              type="danger"
              size="mini"
              @click="deleteDirective([scope.row.id])"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <Pagination
        v-if="directivesListData.total > 8"
        :handleSizeChange="handleSizeChange"
        :handleCurrentPageChange="handleCurrentPageChange"
        :total="directivesListData.total"
      />
    </div>
<!--    <el-dialog title="上传模板" :visible.sync="dialogFormVisible">-->
<!--      <div style="text-align: center">-->
<!--        <div style="margin-bottom: 20px">-->
<!--          <el-button type="primary" icon="el-icon-download" @click="downLoadExcel">下载模板</el-button>-->
<!--        </div>-->
<!--        <el-upload-->
<!--            ref="uploadRef"-->
<!--            :multiple = false-->
<!--            list-type="picture-card"-->
<!--            action="/api/cloud/file/uploadFile"-->
<!--            :data="uploadExtraParams"-->
<!--            :headers="uploadHeaders"-->
<!--            :accept="fileTypeAccepts[materailType]"-->
<!--            class="apk-upload"-->
<!--            :file-list="file"-->
<!--            :auto-upload="false"-->
<!--            :on-success="successCall"-->
<!--            :on-error="errorCall"-->
<!--        >-->
<!--          <i class="el-icon-plus"></i>-->
<!--          <div slot="tip" class="el-upload__tip">单个文件上传，上传格式为{{fileTypeAccepts[materailType]}}文件</div>-->
<!--        </el-upload>-->
<!--      </div>-->

<!--      <el-table-->
<!--          ref="multipleTable"-->
<!--          :data="gridData"-->
<!--          tooltip-effect="dark"-->
<!--          style="width: 100%"-->
<!--          v-show = false-->
<!--      >-->
<!--        <el-table-column-->
<!--            :prop="c.key"-->
<!--            :label="c.label"-->
<!--            show-overflow-tooltip-->
<!--            v-for="c in gridDataColumns"-->
<!--            :key="c.key"-->
<!--        />-->
<!--      </el-table>-->
<!--      <div slot="footer" class="dialog-footer">-->
<!--        <el-button @click="dialogFormVisible = false">取 消</el-button>-->
<!--      </div>-->
<!--    </el-dialog>-->
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import moment from "moment";
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      gridDataColumns: [
        {
          key: "data",
          label: "指令名称",
        }
        ],
      gridData:[{"data":"1"}],
      dialogFormVisible:false,
      materailType: "excel",
      videoImage: require("@/assets/music.svg"),
      fileTypeAccepts: {
        excel: ".xls,.xlsx",
      },
      columns: [
        {
          key: "instruction_name",
          label: "指令名称",
        },
        {
          key: "instruction_code",
          label: "指令码",
        },
        {
          key: "unicode",
          label: "编码格式",
          format: function(val) {
            return val === '1' ? '字符串' : val === '2' ? '16进制' : '';
          }
        },
        {
          key: "send_type",
          label: "协议类型",
        },

        {
          key: "type_name",
          label: "指令类别",
        },
        {
          key: "create_time",
          label: "创建时间",
        },
        {
          key: "create_by",
          label: "创建者",
        },
        {
          key: "enter_space_name",
          label: "加入回车/空格",
        },
        {
          key: "remark",
          label: "备注",
        },
      ],
      directivesListData: {},
      directiveTypes: [],
      selectedDirectives: [],
      selectedType: "",
      searchValue: "",
      options: [
        {
          label: "1111111111",
          value: 1,
        },
        {
          label: "2222222222",
          value: 2,
        },
        {
          label: "3333333333",
          value: 3,
        },
      ],
    };
  },
  mounted() {
    this.queryDirectivesList({});
    this.getDirectiveTypes();
  },
  methods: {
    search() {
      this.queryDirectivesList({
        instruction_name: this.searchValue,
        type_id: this.selectedType,
      });
    },
    uploadLoadExcel(){

    },
    successCall(ret) {
      this.$changeLoadingState(false);
      if (ret && ret.status === "success") {
        this.fileSuccessArr.push(ret.data && ret.data.id || '');
        if (this.fileSuccessArr.length === this.file.length) {
          // 全部上传成功
          this.close();
          this.$emit('success');
        }
      } else {
        this.$message.error(ret && ret.msg);
      }
    },
    errorCall(ret) {
      this.$changeLoadingState(false);
      this.$message.error(ret && ret.msg);
    },
    uploadExtraParams() {
      let params = {
        file_type: this.materailType
      };
      return params;
    },
    uploadHeaders() {
      return {
        token: this.loginAccountInfo.token
      }
    },
    deleteDirective(ids) {
      this.$request.deleteDirective({ ids }).then((res) => {
        if (res.status != "success") {
          this.$message.error(res.msg);
          return;
        }
        this.$message({
          message: "删除成功",
          type: "success",
        });
        this.queryDirectivesList({});
      });
    },
    getDirectiveTypes() {
      this.$request
        .queryDirectiveTypesList({ page_size: 999, page_num: 1 })
        .then((res) => {
          if (res.status != "success") {
            this.$message.error(res.msg);
            return;
          }
          this.directiveTypes = res?.data?.result || [];
        });
    },
    queryDirectivesList({ page_num = 1, page_size = 8 }) {
      this.$request
        .queryDirectivesList({
          page_num,
          page_size,
          instruction_name: this.searchValue,
          type_id: this.selectedType,
        })
        .then((res) => {
          if (res.status != "success") {
            this.$message.error(res.msg);
            return;
          }
          this.directivesListData = res?.data || {};
        });
    },
    getTime(t) {
      return moment(t).format("YYYY-MM-DD HH:mm");
    },
    handleSizeChange(v) {
      this.queryDirectivesList({ page_size: v });
    },
    toEdit(id) {
      this.$router.push({
        path: "/privateResource/manage/edit",
        query: {
          id,
        },
      });
    },
    handleCurrentPageChange(v) {
      this.queryDirectivesList({ page_num: v });
    },
    handleSelectionChange(val) {
      this.selectedDirectives = val;
    },
  },
};
</script>

<style lang='scss' scoped>
.directivesWrap {
  background-color: #fff;
  .searchWrap {
    display: flex;
    border-bottom: 1px solid #e6e6e6;
    padding: 16px 0;
    align-items: center;
    .label {
      margin-left: 16px;
    }
    .el-input {
      width: 200px !important;
    }
    .el-button {
      margin-left: 16px;
    }
  }
  .operations {
    margin: 24px 24px 0;
  }
  .directivesList {
    padding: 10px 24px 24px;
  }
}
</style>