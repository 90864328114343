var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"projectsWrap"},[_c('div',{staticClass:"searchWrap"},[_c('span',{staticClass:"label"},[_vm._v(" 模板名称： ")]),_c('el-input',{attrs:{"clearable":"","placeholder":"请输入模板名称："},model:{value:(_vm.searchValue),callback:function ($$v) {_vm.searchValue=$$v},expression:"searchValue"}}),_c('el-button',{attrs:{"type":"primary","icon":"el-icon-search"},on:{"click":_vm.search}},[_vm._v("搜索")])],1),_c('el-table',{staticClass:"projectsTable",staticStyle:{"width":"100%"},attrs:{"data":_vm.tableDataInfo.result,"border":""}},[_c('el-table-column',{attrs:{"type":"selection","width":"55"}}),_vm._l((_vm.tableColumns),function(c){return _c('el-table-column',{key:c.prop,attrs:{"label":c.label,"width":c.width},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(c.prop.indexOf('time') > -1)?_c('div',[_vm._v(" "+_vm._s(_vm.dateTransform(scope.row[c.prop]))+" ")]):(c.prop == 'status')?_c('el-tag',{attrs:{"type":_vm.TEMPLATES_STATUS[scope.row[c.prop]].class}},[_vm._v(" "+_vm._s(_vm.TEMPLATES_STATUS[scope.row[c.prop]].text)+" ")]):(c.prop == 'project_img')?_c('div',[(scope.row[c.prop])?_c('img',{staticClass:"projectImg",attrs:{"src":scope.row[c.prop]}}):_vm._e()]):(
            c.prop == 'operations' && scope.row.status == '0' && _vm.isManager
          )?_c('div',{staticClass:"operations"},[_c('el-button',{attrs:{"size":"mini","type":"primary"},on:{"click":function($event){return _vm.agree(scope.row.id)}}},[_vm._v(" 同意 ")]),_c('el-button',{attrs:{"size":"mini","type":"danger"},on:{"click":function($event){return _vm.showDialog(scope.row.id)}}},[_vm._v(" 驳回 ")])],1):_c('span',{staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(scope.row[c.prop]))])]}}],null,true)})})],2),(_vm.tableDataInfo.total > 8)?_c('Pagination',{attrs:{"handleSizeChange":(v) => {
        this.getTableData({ page_size: v });
      },"handleCurrentPageChange":(v) => {
        this.getTableData({ page_num: v });
      },"total":_vm.tableDataInfo.total}}):_vm._e(),_c('el-dialog',{attrs:{"title":"驳回原因","visible":_vm.dialogVisible,"width":"30%","center":true,"custom-class":"newProject","before-close":() => {
        this.dialogVisible = false;
      }}},[_c('div',[_c('el-input',{attrs:{"type":"textarea","rows":4,"placeholder":"请输入内容"},model:{value:(_vm.reason),callback:function ($$v) {_vm.reason=$$v},expression:"reason"}})],1),_c('span',{staticClass:"shareFooter",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.submit}},[_vm._v("提交")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }