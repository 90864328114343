var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.show)?_c('div',{staticClass:"bg"},[_c('div',{staticClass:"preview-top-container"},[_c('span',[_vm._v("页面：")]),_c('el-select',{ref:"pageSelector",staticClass:"select",attrs:{"placeholder":"请选择","size":"medium"},on:{"change":_vm.pageChange},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}},_vm._l((_vm.pageOptions),function(item){return _c('el-option',{key:item.value + '',staticClass:"pageItem",attrs:{"label":item.label,"value":item.value + ''}},[_c('span',[_vm._v(" "+_vm._s(item.label)+" ")])])}),1),_c('el-button',{staticClass:"close",on:{"click":_vm.close}},[_vm._v("关闭")])],1),_c('div',{staticClass:"canvas-container"},[_c('div',{ref:"canvas",staticClass:"canvas",style:({
        width: _vm.changeStyleWithScale(_vm.getCanvasStyleData.width) + 'px',
        height: _vm.changeStyleWithScale(_vm.getCanvasStyleData.height) + 'px',
        backgroundImage: `url(${_vm.getCanvasStyleData.backPicSrc}) `,
        backgroundSize: `100% 100%`,
        backgroundRepeat: 'no-repeat',
        transformOrigin: '0px 0px'
      })},_vm._l((_vm.useableComponentsData),function(item){return _c('ComponentWrapper',{key:item.id,attrs:{"config":item,"allBtnPressMap":_vm.allBtnPressMap,"currentComps":_vm.currentComponent},on:{"changeCurComp":_vm.changeCurComp,"changePage":_vm.changePage,"changeBtnPress":_vm.changeBtnPress}})}),1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }