import { render, staticRenderFns } from "./leftComp.vue?vue&type=template&id=40998eaa&scoped=true"
import script from "./leftComp.vue?vue&type=script&lang=js"
export * from "./leftComp.vue?vue&type=script&lang=js"
import style0 from "./leftComp.vue?vue&type=style&index=0&id=40998eaa&prod&lang=scss"
import style1 from "./leftComp.vue?vue&type=style&index=1&id=40998eaa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40998eaa",
  null
  
)

export default component.exports