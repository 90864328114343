var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"editor",class:{ edit: _vm.isEdit },style:({
    width: _vm.changeStyleWithScale(_vm.projectStyleData.width) + 'px',
    height: _vm.changeStyleWithScale(_vm.projectStyleData.height) + 'px',
    transformOrigin: '0px 0px'
  }),attrs:{"id":"editor"},on:{"contextmenu":_vm.handleContextMenu,"mousedown":_vm.handleMouseDown}},[_c('Grid'),_vm._l((_vm.usableComponentsData),function(item,index){return _c('Shape',{key:item.id,class:{
      lock: item.isLock,
      commonZ: true
    },style:(_vm.getShapeStyle(item.style)),attrs:{"default-style":item.style,"active":item.id === (_vm.curComponent || {}).id,"element":item,"isEcological":item.compType === _vm.ECOLOGICAL_SIGN,"index":index}},[(item.component != 'v-text')?_c(item.component,{tag:"component",staticClass:"component",style:(_vm.getComponentStyle(item.style, item)),attrs:{"id":'component' + item.id,"prop-value":item.propValue,"styleDatas":item.style,"componentIndex":index,"element":item}}):_c(item.component,{tag:"component",staticClass:"component",style:(_vm.getComponentStyle(item.style)),attrs:{"id":'component' + item.id,"prop-value":item.propValue,"element":item},on:{"input":_vm.handleInput}})],1)}),_c('MarkLine'),_c('Area',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowArea),expression:"isShowArea"}],attrs:{"start":_vm.start,"width":_vm.width,"height":_vm.height},on:{"areaMove":_vm.handleAreaMove}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }