<template>
  <div class="keysWrap">
    <el-form :inline="true" size="mini" :model="form" class="search-form">
      <el-form-item label="设备SN">
        <el-input v-model="form.sn" placeholder="设备SN关键字"></el-input>
      </el-form-item>
      <el-form-item label="激活用户(手机号)">
        <el-input
          v-model="form.phone"
          placeholder="用户手机号关键字"
        ></el-input>
      </el-form-item>
      <el-form-item label="屏蔽数据(用途中存在测试字样)">
        <el-radio v-model="form.test" label="1">是</el-radio>
        <el-radio v-model="form.test" label="2">否</el-radio>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSearch">查询</el-button>
      </el-form-item>
    </el-form>
    <div>
      <el-button
        type="primary"
        icon="el-icon-plus"
        class="addManager"
        @click="switchDialogVisible(true, 'generateKey')"
      >
        生成密钥
      </el-button>
    </div>
    <el-table
      :data="tableDataInfo.result"
      style="width: 100%"
      class="keysTable"
      border
    >
      <el-table-column
        v-for="c in tableColumns"
        :key="c.prop"
        :label="c.label"
        :width="c.width"
        :fixed="c.fixed"
      >
        <template slot-scope="scope">
          <div v-if="c.prop.indexOf('time') > -1">
            {{ dateTransform(scope.row[c.prop]) }}
          </div>

          <div v-else-if="c.prop == 'level'">
            {{ getLevelName(scope.row.level) }}
          </div>

          <div
            :class="[statusConfig[scope.row.status].class]"
            v-else-if="c.prop == 'status'"
          >
            {{ statusConfig[scope.row.status].text }}
          </div>
          <div class="operations" v-else-if="c.prop == 'operations'">
            <el-popconfirm
              title="确定要重置吗？"
              @confirm="resetClick(scope.row.id)"
            >
              <el-button
                slot="reference"
                :disabled="scope.row.status === 0"
                size="mini"
                type="primary"
                >重置</el-button
              >
            </el-popconfirm>
            <el-button
              size="mini"
              type="primary"
              @click="bindSecretKey(scope.row.id)"
            >
              绑定
            </el-button>
            <el-button
              size="mini"
              type="primary"
              :disabled="scope.row.status !== 0"
              @click="editSecretTime(scope.row.id)"
            >
              修改密钥时长
            </el-button>
          </div>

          <span v-else>{{ scope.row[c.prop] }}</span>
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      v-if="tableDataInfo.total > 8"
      :handleSizeChange="handleSizeChange"
      :handleCurrentPageChange="handleCurrentPageChange"
      :total="tableDataInfo.total"
    />
    <CommonDialog
      v-if="dialogVisible"
      :visible="dialogVisible"
      :switchDialogVisible="switchDialogVisible"
      :activeDialogKey="activeDialogKey"
      :refresh="refresh"
      :secretKeyId="activeSecretKeyId"
    />
    <el-dialog width="300px" title="修改密钥时长" :visible.sync="editVisible">
      <el-date-picker
        v-model="editTime"
        type="datetime"
        placeholder="选择日期时间"
        value-format="yyyy-MM-dd HH:mm:ss"
        :pickerOptions="pickerOptions"
      >
      </el-date-picker>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editHide">取 消</el-button>
        <el-button type="primary" @click="editConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import Pagination from '@/components/Pagination'
import CommonDialog from './commonDialog.vue'
import { mapState } from 'vuex'
import { getTimeStr } from '@/utils/utils'
export default {
  data() {
    return {
      editVisible: false,
      editTimeId: '',
      editTime: '',
      form: {
        test: '1',
        sn: '',
        phone: '',
      },
      statusConfig: {
        0: {
          text: '未激活',
          class: 'inactive',
        },
        1: {
          text: '已激活',
          class: 'active',
        },
      },
      tableColumns: [
        {
          label: '密钥',
          prop: 'secret_key',
          width: 120,
        },
        {
          label: '用途',
          prop: 'use_scenes',
          width: 120,
        },
        {
          label: '创建人',
          prop: 'create_by',
        },
        {
          label: '密钥时长',
          prop: 'keys_long',
          width: 140,
        },
        {
          label: '创建时间',
          prop: 'create_time',
          width: 140,
        },
        {
          label: '密钥激活时间',
          prop: 'activation_time',
          width: 140,
        },
        {
          label: '状态',
          prop: 'status',
          width: 80,
        },
        {
          label: '设备SN',
          prop: 'sn',
        },
        {
          label: '密钥级别',
          prop: 'level',
          width: 120,
        },
        {
          label: '激活用户',
          prop: 'bind_user_name',
          width: 140,
        },
        {
          label: '用户电话',
          prop: 'bind_phone',
          width: 100,
        },
        {
          label: '操作',
          prop: 'operations',
          width: '270',
          fixed: 'right',
        },
      ],
      tableDataInfo: {},
      resetPwdForm: {},
      searchValue: '',
      activeManagerId: '',
      activeSecretKeyId: '',
      activeDialogKey: '',
      dialogVisible: false,
      pageInfo: {
        page_num: 1,
        page_size: 8,
      },
    }
  },
  computed: {
    ...mapState(['projectLevels']),
    pickerOptions() {
      // 选择的日期
      let curDate = this.editTime ? getTimeStr(this.editTime) : ''
      // 最小日期
      let minTime = new Date()
      // 最大日期
      let minDate = getTimeStr(minTime)
      // 前推一天，防止当天日期无法选择
      let preTime = minTime
      if (getTimeStr(minTime, 'HH:mm:ss') !== '00:00:00') {
        preTime = minTime - 24 * 3600000
      }
      // 如果选择的日期为最小日期，则设置为最小日期的时分秒
      let str = ''
      if (curDate === minDate) {
        str = getTimeStr(minTime, 'HH:mm:ss')
      } else {
        str = '00:00:00'
      }
      let maxStr = '23:59:59'
      // if (curDate === maxDate) {
      //     maxStr = getTimeStr(maxTime, 'HH:mm:ss');
      // } else {
      //     maxStr = '23:59:59';
      // }
      return {
        disabledDate: (date) => {
          // return date.getTime() < new Date(this.initTime).getTime()
          // || date.getTime() > new Date().getTime();
          return date.getTime() < preTime
        },
        selectableRange: str + ' - ' + maxStr,
      }
    },
  },
  components: {
    Pagination,
    CommonDialog,
  },
  mounted() {
    this.$store.dispatch('getProjectLevelsAction', {})
    this.getTableData({})
  },
  methods: {
    editHide() {
      this.editTimeId = ''
      this.editTime = ''
      this.editVisible = false
    },
    editConfirm() {
      if (!this.editTime) {
        this.$message.error('时间不能为空')
        return
      }
      // 如果选定时间小于当前时间需要提示，不能保存
      let editTimeNum = new Date(this.editTime).getTime()
      let nowNum = new Date().getTime()
      if (editTimeNum <= nowNum) {
        this.$message.error('不能小于当前时间')
        return
      }
      // 更改
      this.$request
        .updateEndingTime({
          id: this.editTimeId,
          end_time: new Date(this.editTime).getTime(),
        })
        .then((res) => {
          if (res.status != 'success') {
            this.$message.error(res.msg)
            return
          }
          this.editHide()
          this.getTableData({})
        })
    },
    onSearch() {
      this.getTableData({ page_num: 1 })
    },
    getLevelName(l) {
      const level =
        this.projectLevels.find((item) => item.project_code == l) || {}
      return level.project_type || '未知级别'
    },
    refresh(notFirstPage) {
      this.switchDialogVisible(false)
      this.getTableData(notFirstPage ? {} : { page_num: 1 })
    },
    bindSecretKey(id) {
      this.activeSecretKeyId = id
      this.dialogVisible = true
    },
    editSecretTime(id) {
      this.editTimeId = id
      this.editVisible = true
    },
    resetClick(id) {
      this.$request.updateSecretKeyStatus({ id }).then((res) => {
        if (res.status != 'success') {
          this.$message.error(res.msg)
          return
        }
        this.getTableData({})
      })
    },
    handleEdit() {},
    switchDialogVisible(bol, key) {
      this.activeDialogKey = key
      this.dialogVisible = bol
    },
    handleSizeChange(page_size) {
      this.getTableData({ page_size, page_num: 1 })
    },
    handleCurrentPageChange(page_num) {
      this.getTableData({ page_num })
    },
    getTableData({ page_num, page_size }) {
      if (page_num) {
        this.pageInfo.page_num = page_num
      }
      if (page_size) {
        this.pageInfo.page_size = page_size
      }
      let params = {
        ...this.pageInfo,
        ...this.form,
      }
      this.$request.querySecretKeysList(params).then((res) => {
        if (res.status != 'success') {
          this.$message.error(res.msg)
          return
        }
        this.tableDataInfo = res.data || {}
      })
    },
    dateTransform(d) {
      if (!d) return
      return moment(d).format('YYYY-MM-DD HH:mm')
    },
  },
}
</script>
<style lang="scss" scoped>
.search-form {
  padding-top: 16px;
}
</style>
<style lang="scss">
.keysWrap {
  padding: 0 24px 24px;
  background-color: #fff;
  .addManager {
    margin: 0px 0 16px;
  }
  .keysTable {
    .inactive {
      color: #909399c9;
    }
    .active {
      color: #67c23a;
    }
    .el-button {
      margin-left: 10px !important;
    }
  }
}
</style>
